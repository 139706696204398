<template>
  <section id="unpublished" v-if="!loading">
    <v-sheet
      color="transparent"
      class="d-flex flex-column align-center justify-center pb-10"
      height="500"
      v-if="courses.filter(item => item.status == 'UNPUBLISH').length === 0"
    >
      <div class="">
        <v-img 
          max-width="250" 
          :src="require('../../assets/default/empty_dashboard.png')" />
      </div>
      <h3 class="poppins secondary-1--text fw600 my-5 text-center">
        You currently have no unpublished courses.
      </h3>
      <div
        class="text-decoration-underline f14 poppins primary--text"
        @click="$emit('createCourse')"
      >
        Create Course
      </div>
    </v-sheet>
    <v-card class="custom-border border pa-2" v-else>
      <section class="d-flex flex-row align-center">
        <v-card-title>
          <h4 class="poppins secondary-1--text fw600">Unpublished</h4>
        </v-card-title>
        <v-spacer />
        <div class="d-flex justify-end">
          <v-btn 
            text 
            small
            @click="gallery =  true"
            class="hidden-sm-and-down"
          >
            <v-icon color="" :class="gallery ? 'primary--text' : 'secondary-2--text'">
              mdi-view-gallery-outline
            </v-icon>
          </v-btn>
          <v-btn 
            text 
            small
            @click="gallery = false"
            class="hidden-sm-and-down"
          >
            <v-icon color="" :class="!gallery ? 'primary--text' : 'secondary-2--text'">
              mdi-format-list-bulleted
            </v-icon>
          </v-btn>
        </div>
      </section>
      <v-card-text v-if="courses.filter(item => item.status == 'UNPUBLISH').length>0" class="d-flex" :class="[$vuetify.breakpoint.mobile && 'justify-center', gallery ? 'flex-wrap' : 'flex-column']">
        <CourseCardGallery 
          v-if="gallery"
          v-for="(item,i) in courses.filter(item => item.status == 'UNPUBLISH')"
          :key="item.id"
          :i="i" 
          :item="item" 
          :gallery="gallery"
          class="ma-3"
          @fetchCourses="getCourses"
        />
        <CourseCardList 
          v-if="!gallery"
          v-for="(item,i) in courses.filter(item => item.status == 'UNPUBLISH')"
          :key="item.id"
          :i="i" 
          :item="item" 
          class="ma-3"
          @fetchCourses="getCourses"
        />
      </v-card-text>
      <!-- <div class="ma-5" :class="gallery && 'coursebox'">
        <CourseCard 
          v-for="(item, i) in courses.filter(item => item.status == 'UNPUBLISH')" 
          :key="i" :i="i" 
          :item="item" 
          :courses="courses"
          :gallery="gallery" 
          class="ma-5"/>
      </div> -->
      <!-- <v-row no-gutters class="my-5">
        <v-col cols="12" :md="gallery && 3"  :sm="gallery && 4"
          v-for="(item,i) in courses.filter(item => item.status == 'UNPUBLISH')" 
            :key="i" >
            <CourseCardGallery 
              :i="i" 
              :item="item" 
              :courses="courses"
              :gallery="gallery"
              class="ma-1"
            />
        </v-col>
      </v-row> -->
    </v-card>
  </section>
  <circular v-else />
</template>

<script>
import { mapActions, mapState } from "vuex";
import CourseCardGallery from "../../components/teacher/course/CourseCardGallery.vue";
import CourseCardList from "../../components/teacher/course/CourseCardList.vue";
export default {
  components: {
    CourseCardGallery,
    CourseCardList,
  },
  data: () => ({
    loading: true,
    gallery: true,
    filter: null,
  }),
  computed: {
    ...mapState("instructor", {
      courses: (state) => state.courses,
    }),
  },
  mounted() {
    window.addEventListener('resize', this.onResize, {passive: true})
    this.onResize()
    this.getCourses()
  },
  methods: {
    ...mapActions("instructor", ["getCoursesAction"]),

    onResize(){
      if(this.$vuetify.breakpoint.smAndDown || this.$vuetify.breakpoint.mobile){
        this.gallery = false
      }
    },

    getCourses(){
      this.getCoursesAction("").finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>  